<template>
    <div class="PagedDataGrid-container p-0">
        <grid-freeze
            :datasetId="this.datasetId"
            :projectId="parseInt(this.projectIds)"
            :colsCount="this.displayFields.length"
            @freeze="setFreeze"
            ref="freeze-component"
        ></grid-freeze>

        <b-col class="p-0" ref="grid" id="PagedDataGrid"></b-col>
    </div>
</template>

<script>
import {
    GridPlugin,
    Sort,
    Page,
    Filter,
    Freeze,
    CommandColumn,
    Search,
    Resize,
    Toolbar,
    Grid,
    Reorder,
    ExcelExport,
} from '@syncfusion/ej2-vue-grids';
import {
    DataManager,
    Query,
    ODataAdaptor,
    UrlAdaptor,
    WebApiAdaptor,
    Predicate,
} from '@syncfusion/ej2-data';
import { datasetService } from '../../services/dataset.service';
import {
    dateFunctions,
    CUSTOM_DATE_FILTERS,
} from '../../utilities/dateFunctions';
import Vue from 'vue';
import { commonService } from '../../services/common.service';
import { integer } from 'vuelidate/lib/validators';
import { stringToNumber } from '@syncfusion/ej2-charts';
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import { Menu } from '@syncfusion/ej2-navigations';
import { DateRangePicker } from '@syncfusion/ej2-calendars';
import GridFreeze from './GridFreeze.vue';
import { userService } from '../../services/users.service';

import _ from 'lodash';
import moment from 'moment';

Vue.use(GridPlugin);
export default {
    name: 'PagedDataGrid',
    components: { GridFreeze },
    props: {
        datasetId: Number,
        projectIds: String,
        dataSetName: String,
        datasetType: String,
        dateFilterColumn: String,
    },
    data() {
        return {
            grid: null,
            displayFields: [],
            dataManager: null,
            SERVICE_URI: '',
            specialDateFilters: [],
            columnNames: null,
            columnTypes: null,
            pageSettings: {},
            columnDateFormat: this.getColumnDateFormat(),
            columnTemplate: () => {
                return {
                    template: Vue.component('columnTemplate', {
                        template: `<div class="image">
                                        <a  v-on:click="hyperlinkClick">{{cData}}</a>
                                        </div>`,
                        data: () => {
                            return {
                                data: {},
                            };
                        },
                        computed: {
                            cData: function() {
                                var value = this.data[this.data.column.field];
                                return value;
                            },
                        },
                        methods: {
                            hyperlinkClick: (args) => {
                                var grid = this.grid;
                                var rowInfo = grid.getRowInfo(
                                    args.target.closest('td')
                                );
                                var cellValue =
                                    rowInfo.rowData[rowInfo.column.field];
                                let hyperNumber = rowInfo.column.field.includes(
                                    '_'
                                )
                                    ? rowInfo.column.field.split('_')
                                    : 0;
                                let path =
                                    hyperNumber != 0
                                        ? 'DocHyperlinkPath_' + hyperNumber[1]
                                        : 'DocHyperlinkPath';
                                var pathValue = rowInfo.rowData[path];
                                let source =
                                    hyperNumber != 0
                                        ? 'DocHyperlinkSource_' + hyperNumber[1]
                                        : 'DocHyperlinkSource';
                                var sourceValue = rowInfo.rowData[source];
                                let filename = cellValue;
                                if (sourceValue === 'Blob') {
                                    commonService.downloadDocument(
                                        filename,
                                        'Blob',
                                        pathValue
                                    );
                                } else if (sourceValue === 'Web') {
                                    let url = pathValue;
                                    window.open(url, '_blank');
                                } else if (sourceValue === 'NetworkDrive') {
                                    commonService.downloadDocument(
                                        filename,
                                        'NetworkDrive',
                                        pathValue
                                    );
                                }
                            },
                        },
                    }),
                };
            },
            filterLoading: false,
            hidetableSelect: false,
            openNewTable: true,
            dataPostingTool: false,
            reportingToolDisplay: false,
            titleBlockDisplay: false,
            formatOptions: { type: 'dateTime', format: 'MM/dd/yyyy hh:mm:ss' },
            dateValue: [],
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            deleteCommands: [
                {
                    type: 'Delete',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-delete e-icons',
                    },
                },
            ],
            filterCommands: [
                {
                    type: 'Filter',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-filter e-icons',
                    },
                },
            ],
            flag: false,
            filterDialogCol: '',
            datasetNameHeader: localStorage.getItem('displayName'),
            secondLvlFilter: [],
            filteredColumnId: [],
            filteredsortedColumns: [],
            dateRangEnabled: false,
            selectedFiltersHistory: [],
            gridFiltersHistory: [],
            selectedGridFilters: [],
            userPortalOptionID: 0,
            filterTypeArray: [],
            filterDescription: '',
            filterName: '',
            filterOptionsHistory: null,
            filterCategory: '',
            filterOptions: {
                type: 'Menu',
                columns: [],
            },
            pickerColumnName: '',
            totalRecordsCount: 0,
            frozenRows: 0,
            frozenColumns: 0,
            displayFieldChange: false,
        };
    },
    provide: {
        grid: [Page],
    },
    async created() {
        this.portalPageSettings();
    },
    methods: {
        ...mapActions('filteroptions', {
            getAllFilterOptions: 'getAllFilterOptions',
        }),
        ...mapActions('projects', {
            setSelectedProjects: 'selectProjects',
        }),
        ...mapActions('gridFilters', [
            'setFilter',
            'setSecondFilter',
            'setGridFilters',
            'setHistoryFilters',
            'setFreezeVals',
            'clearCurrentGridFilter',
            'clearGridFilterColumn',
        ]),

        dataStateChange: function(state) {},

        createDataGrid: function() {
            this.setSpecialDateFilters();
            this.setFiltersOnGrid();
            this.resetDatagrid();

            this.grid = null;
            Grid.Inject(
                Freeze,
                Filter,
                Page,
                Sort,
                Search,
                Toolbar,
                Resize,
                Reorder,
                ExcelExport
            );

            let token = commonService.getCookie('accessToken');

            this.SERVICE_URI = datasetService.getPagedDataGrid(
                this.projectIds,
                this.datasetId
            );
            this.dataManager = new DataManager({
                url: this.SERVICE_URI,
                adaptor: new UrlAdaptor(),
                headers: [
                    {
                        Authorization: 'Bearer ' + token,
                    },
                ],
            });
            this.grid = new Grid({
                allowSorting: true,
                allowResizing: true,
                allowExcelExport: true,
                selectionSettings: {
                    persistSelection: true,
                },
                allowFiltering: true,
                pageSettings: this.pageSettings,
                filterSettings: this.filterOptions,
                dataBound: this.dataBoundGrid,
                load: this.pageCenter,
                height: 520,
                width: 2000,
                allowReordering: true,
                allowPaging: true,
                actionBegin: this.actionBegin,
                actionComplete: this.actionComplete,
                dataSource: this.dataManager,
                frozenColumns: this.frozenColumns,
                frozenRows: this.frozenRows,
            });

            this.grid.columns = this.displayFields;
            this.grid.appendTo('#PagedDataGrid');

            if (this.dataManager.requests.length > 0) {
                this.setFilter(this.dataManager.requests.at(-1).data);
            }
            this.setSecondFilter(this.secondLvlFilter);
        },
        setSpecialDateFilters() {
            this.specialDateFilters = [];
            if (this.gridFilters) {
                let tempGridFilters = this.gridFilters.find((filter) => {
                    if (
                        filter.dataSetId === this.datasetId &&
                        filter.projectId === parseInt(this.projectIds)
                    ) {
                        return filter;
                    }
                });
                if (tempGridFilters && tempGridFilters.selectedFilters) {
                    tempGridFilters.selectedFilters.forEach((filter) => {
                        if (filter.specialPredicate) {
                            this.specialDateFilters.push({
                                filter: filter.specialPredicate,
                                colName: filter.field,
                            });
                        }
                    });
                }
            }
        },
        setFiltersOnGrid() {
            if (this.gridFilters) {
                let tempGridFilters = this.gridFilters.find((filter) => {
                    if (
                        filter.dataSetId === this.datasetId &&
                        filter.projectId === parseInt(this.projectIds)
                    ) {
                        return filter;
                    }
                });
                if (tempGridFilters) {
                    let distinctField = _.uniqBy(
                        tempGridFilters.selectedFilters,
                        'field'
                    ).map((itm) => itm.field);
                    this.filterOptions.columns = [];
                    distinctField.forEach((distCol) => {
                        let tempFilterArr = tempGridFilters.selectedFilters.filter(
                            (filCol) => filCol.field === distCol
                        );

                        tempFilterArr.forEach((filter) => {
                            this.filterOptions.columns.push({
                                field: filter.field,
                                operator: filter.operator,
                                value: filter.specialPredicate
                                    ? dateFunctions.calcDataManagerDateFilter(
                                          filter.specialPredicate
                                      )
                                    : filter.value,
                                predicate: filter.predicate,
                            });
                            if (filter.field == this.dateFilterColumn) {
                                try {
                                    this.$store.dispatch(
                                        'emergencyManagement/applyQuickTimeFilter',
                                        filter.specialPredicate
                                            ? dateFunctions.calcDataManagerDateFilter(
                                                  filter.specialPredicate,
                                                  true
                                              )
                                            : filter.value.toISOString()
                                    );
                                } catch {
                                    this.$store.dispatch(
                                        'emergencyManagement/applyQuickTimeFilter',
                                        filter.specialPredicate
                                            ? dateFunctions.calcDataManagerDateFilter(
                                                  filter.specialPredicate,
                                                  true
                                              )
                                            : filter.value
                                    );
                                }
                            }
                        });
                    });
                } else {
                    this.filterOptions.columns = [];
                }
            }
        },
        setFilterTypes() {
            if (this.gridFilters) {
                let tempGridFilters = this.gridFilters.find((filter) => {
                    if (
                        filter.dataSetId === this.datasetId &&
                        filter.projectId === parseInt(this.projectIds)
                    ) {
                        return filter;
                    }
                });
                if (tempGridFilters) {
                    let distinctField = _.uniqBy(
                        tempGridFilters.selectedFilters,
                        'field'
                    ).map((itm) => itm.field);
                    this.filterOptions.columns = [];
                    distinctField.forEach((distCol) => {
                        let tempFilterArr = tempGridFilters.selectedFilters.filter(
                            (filCol) => filCol.field === distCol
                        );
                        var colItem = this.grid.getColumnByField(distCol);
                        colItem.filter = { type: tempFilterArr[0].filterType };
                    });
                }
            }
        },
        pageCenter() {},

        actionBegin: function(args) {
            if (args.action == 'clear-filter') {
                let colFilter = {};
                colFilter.projectId = +this.projectIds;
                colFilter.dataSetId = this.datasetId;
                colFilter.value = args.field;

                this.clearGridFilterColumn(colFilter);

                if (this.dataManager.requests.length > 0) {
                    this.setFilter(this.dataManager.requests.at(-1).data);
                }
            }
            if (
                args.requestType == 'filterchoicerequest' ||
                args.requestType == 'filtersearchbegin'
            ) {
                if (
                    args.column &&
                    args.column.type == 'number' &&
                    args.operator == 'equal'
                ) {
                    args.operator = 'contains';
                }
                args.filterChoiceCount = this.totalRecordsCount;
                args.query.distincts = [];
                let filterCriteria = [];
                args.query.select(args.filterModel.options.field);
                if (
                    Object.keys(args.filterModel.existingPredicate).length > 0
                ) {
                    Object.keys(args.filterModel.existingPredicate).forEach(
                        (pred, indx_1) => {
                            if (
                                args.filterModel.existingPredicate[pred] !==
                                undefined
                            ) {
                                args.filterModel.existingPredicate[
                                    pred
                                ].forEach((item, indx_2) => {
                                    filterCriteria.push({
                                        field: item.field,
                                        operator: item.operator,
                                        value: item.value,
                                    });
                                });
                            }
                        }
                    );
                }

                let finalPredicate = null;
                let fieldPredicates = {};
                let fieldValue = null;

                for (const criteria of filterCriteria) {
                    const predicate = new Predicate(
                        criteria.field,
                        criteria.operator,
                        criteria.value
                    );

                    if (!fieldPredicates[criteria.field]) {
                        fieldPredicates[criteria.field] = predicate;
                        fieldValue = predicate.value;
                    } else {
                        fieldValue = fieldValue + '~~' + predicate.value;

                        predicate.value = fieldValue;
                        fieldPredicates[criteria.field] = predicate;
                    }
                }

                for (const key in fieldPredicates) {
                    if (finalPredicate === null) {
                        finalPredicate = fieldPredicates[key];
                    } else {
                        finalPredicate = finalPredicate.and(
                            fieldPredicates[key]
                        );
                    }
                }
                if (finalPredicate !== null) {
                    args.query.where(finalPredicate);
                }
            }

            this.grid.columns.forEach((x) => {
                if (x.type == null) {
                    x.type = 'string';
                }
            });
            var col = this.grid.getColumnByField(args.currentFilteringColumn);
            if (
                args.requestType === 'filtering' &&
                args.action != 'clearFilter' &&
                col &&
                (col.type === 'date' || col.type === 'datetime')
            ) {
                if (col.field === this.dateFilterColumn) {
                    this.$emit('resetActionDropdown');
                }
                if (this.specialDateFilters.length > 0) {
                    let indx = args.columns.findIndex(
                        (itm) => itm.field === col.field
                    );
                    args.columns[indx].operator = 'greaterthanorequal';
                    args.columns[
                        indx
                    ].value = dateFunctions.calcDataManagerDateFilter(
                        this.specialDateFilters.find(
                            (item) => item.colName === col.field
                        ).filter
                    );
                    args.currentFilterObject.operator = 'greaterthanorequal';
                    args.currentFilterObject.value = dateFunctions.calcDataManagerDateFilter(
                        this.specialDateFilters.find(
                            (item) => item.colName === col.field
                        ).filter
                    );
                } else {
                    var datevalue = this.dateValue;
                    if (
                        document.querySelector(
                            '.e-flm_optrdiv .e-dropdownlist'
                        ) &&
                        document.querySelector('.e-flm_optrdiv .e-dropdownlist')
                            .ej2_instances[0].value === 'between'
                    ) {
                        args.cancel = true;
                        setTimeout(() => {
                            var vm = this;
                            vm.dateRangEnabled = true;
                            vm.grid.filterByColumn(
                                args.currentFilteringColumn,
                                'greaterthanorequal',
                                datevalue[0]
                            );
                        }, 1000);
                    } else {
                        if (
                            document.querySelector(
                                '.e-flm_optrdiv .e-dropdownlist'
                            ) &&
                            document.querySelector(
                                '.e-flm_optrdiv .e-dropdownlist'
                            ).ej2_instances[0].value !== 'between'
                        ) {
                            args.cancel = true;
                            setTimeout(() => {
                                this.grid.filterByColumn(
                                    args.currentFilterObject.field,
                                    args.currentFilterObject.operator,
                                    args.currentFilterObject.value
                                );
                            });
                        }
                    }

                    if (this.dateRangEnabled) {
                        this.dateRangEnabled = false;
                        args.columns.push({
                            actualFilterValue: {},
                            actualOperator: {},
                            field: args.currentFilteringColumn,
                            ignoreAccent: false,
                            isForeignKey: false,
                            matchCase: false,
                            operator: 'lessthanorequal',
                            predicate: 'and',
                            uid: this.grid.getColumnByField(
                                args.currentFilteringColumn
                            )
                                ? this.grid.getColumnByField(
                                      args.currentFilteringColumn
                                  ).uid
                                : 0,
                            value: datevalue[1],
                        });
                    }
                }
            } else if (
                args.action === 'clearFilter' ||
                args.action === 'clear-filter'
            ) {
                const targetField =
                    args.action === 'clearFilter'
                        ? args.currentFilterColumn.field
                        : args.field;

                if (targetField === this.dateFilterColumn) {
                    this.$emit('resetActionDropdown');
                }
                this.grid.removeFilteredColsByField(targetField);

                let tempGridFilters = this.gridFilters.find((filter) => {
                    return (
                        filter.dataSetId === this.datasetId &&
                        filter.projectId === parseInt(this.projectIds)
                    );
                });

                let index = tempGridFilters.selectedFilters.findIndex(
                    (filter) => filter.field === targetField
                );

                while (index >= 0) {
                    tempGridFilters.selectedFilters.splice(index, 1);
                    this.setGridFilters(tempGridFilters);
                    index = tempGridFilters.selectedFilters.findIndex(
                        (filter) => filter.field === targetField
                    );
                }

                this.setSpecialDateFilters();
            } else {
                if (
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist') &&
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist')
                        .ej2_instances[0].value !== 'between'
                ) {
                    args.cancel = true;
                    setTimeout(() => {
                        this.grid.filterByColumn(
                            args.currentFilterObject.field,
                            args.currentFilterObject.operator,
                            args.currentFilterObject.value
                        );
                    });
                }
            }
            if (
                args.requestType === 'filtering' &&
                args.action != 'clearFilter'
            ) {
                this.grid.goToPage(1);
            }
        },
        actionComplete: function(args) {
            if (args.action === 'clearFilter') {
                if (
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist') !==
                    null
                ) {
                    document.querySelector(
                        '.e-flm_optrdiv .e-dropdownlist'
                    ).ej2_instances[0].value = 'equal';
                }
                args.cancel = true;
            }
            if (args.requestType === 'filterafteropen') {
                let indx = this.specialDateFilters.findIndex(
                    (itm) => itm.colName === args.columnName
                );
                if (indx > -1) {
                    if (
                        document.querySelector(
                            '.e-flm_optrdiv .e-dropdownlist'
                        ) !== null
                    ) {
                        document.querySelector(
                            '.e-flm_optrdiv .e-dropdownlist'
                        ).ej2_instances[0].value = this.specialDateFilters[
                            indx
                        ].filter;
                    }
                    args.cancel = true;
                } else {
                    if (
                        document.querySelector('.e-datetime-wrapper') !== null
                    ) {
                        if (
                            document.querySelector('.e-datetime-wrapper')
                                .classList
                        ) {
                            document
                                .querySelector('.e-datetime-wrapper')
                                .classList.remove('e-hide');
                        }
                    }
                    args.cancel = true;
                }
            }
            if (
                (args.requestType == 'refresh' ||
                    args.requestType == 'filterafteropen') &&
                JSON.stringify(this.grid.filterModule.actualPredicate) === '{}'
            ) {
                this.totalRecordsCount = this.grid.pagerModule.pageSettings.properties.totalRecordsCount;
            }
            if (args.requestType == 'filterafteropen') {
                if (
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist') !==
                    null
                ) {
                    document.querySelector(
                        '.e-flm_optrdiv .e-dropdownlist'
                    ).ej2_instances[0].change = (dropdown) => {
                        if (CUSTOM_DATE_FILTERS.includes(dropdown.value)) {
                            args.filterModel.dlgObj.element
                                .querySelector('.e-datetime-wrapper')
                                .classList.add('e-hide');
                            let indx = this.specialDateFilters.findIndex(
                                (itm) => itm.colName === args.columnName
                            );
                            if (indx > -1) {
                                this.specialDateFilters[indx].filter =
                                    dropdown.value;
                            } else {
                                this.specialDateFilters.push({
                                    filter: dropdown.value,
                                    colName: args.columnName,
                                });
                            }
                        } else {
                            if (
                                document.querySelector(
                                    '.e-datetime-wrapper'
                                ) !== null
                            ) {
                                if (
                                    args.filterModel.dlgObj.element.querySelector(
                                        '.e-datetime-wrapper'
                                    ).classList
                                ) {
                                    args.filterModel.dlgObj.element
                                        .querySelector('.e-datetime-wrapper')
                                        .classList.remove('e-hide');
                                }
                            }
                        }
                    };
                }
            }
            if (args.columns) {
                this.gridFiltersHistory = args.columns.map((col) => {
                    let tempType = this.filterTypeArray.find(
                        (itm) => itm.field === col.field
                    );
                    let tempObj = this.specialDateFilters.find(
                        (itm) => itm.colName === col.field
                    );
                    let specialPredicate = tempObj ? tempObj.filter : null;
                    return {
                        field: col.field,
                        operator: col.operator,
                        predicate: col.predicate,
                        specialPredicate,
                        value: col.value,
                        filterType: tempType ? tempType.filterType : 'Menu', //set the default
                    };
                });
                //set to store
                if (this.gridFiltersHistory.length) {
                    let tempGridFilters = {
                        dataSetId: this.datasetId,
                        projectId: parseInt(this.projectIds),
                        selectedFilters: this.gridFiltersHistory,
                    };
                    this.setGridFilters(tempGridFilters);
                    tempGridFilters['createdDate'] = new Date();
                    tempGridFilters['Name'] = this.dataSetName;
                    tempGridFilters[
                        'Description'
                    ] = this.gridFiltersHistory
                        .map((filter) => filter.field)
                        .join(',');
                    this.setHistoryFilters(tempGridFilters);
                }
            }
            if (args.requestType === 'filterafteropen') {
                var obj = document.getElementsByClassName(
                    'e-flmenu e-control e-dialog e-lib e-filter-popup e-popup e-popup-open'
                );
                var grid = this.grid;
                var span = document.createElement('div');
                var filterLable = document.createElement('label');
                filterLable.innerText = 'Filter';
                span.appendChild(filterLable);
                var typeImage = new Image();
                var imagesContext = require.context(
                    '../FilterTypeIcon/images/',
                    false,
                    /\.svg$/
                );
                if (args.columnType === 'string') {
                    typeImage.src = imagesContext('./' + 'text' + '.svg');
                } else if (args.columnType === 'number') {
                    typeImage.src = imagesContext('./' + 'numbers' + '.svg');
                } else if (args.columnType === 'datetime') {
                    typeImage.src = imagesContext('./' + 'date' + '.svg');
                }
                typeImage.classList.add('filter-type-img');

                span.appendChild(typeImage);
                span.classList.add('filter');

                var close = document.createElement('span');
                // close.innerHTML ="<img @click='closeFilter' src='../../assets/close_big.svg' />";
                var dlgObject = args.filterModel.dialogObj
                    ? args.filterModel.dialogObj.element
                    : args.filterModel.dlgObj.element;
                if (dlgObject.querySelector('.e-numerictextbox')) {
                    dlgObject.querySelector(
                        '.e-numerictextbox'
                    ).ej2_instances[0].format = '#.#######';
                }
                if (dlgObject.querySelector('.e-autocomplete')) {
                    dlgObject.querySelector(
                        '.e-autocomplete'
                    ).ej2_instances[0].autofill = false;
                }
                if (
                    dlgObject.getElementsByClassName('customclass').length === 0
                ) {
                    var div = document.createElement('div');
                    div.classList.add('customclass');
                    var ul = document.createElement('ul');
                    div.appendChild(ul);
                    div.prepend(close);
                    div.prepend(span);
                    ul.style.width = '230px';
                    var menuItems = [
                        {
                            text: dlgObject.classList.contains(
                                'e-checkboxfilter'
                            )
                                ? 'CheckBox'
                                : 'Menu',
                            items:
                                dlgObject.querySelector('.e-datetimepicker') ||
                                dlgObject.querySelector('.e-datepicker')
                                    ? [{ text: 'Menu' }]
                                    : [{ text: 'CheckBox' }, { text: 'Menu' }],
                        },
                    ];
                    let menuObj = new Menu(
                        {
                            items: menuItems,
                            showItemOnClick: true,
                            select: this.menuSelect.bind(this),
                        },
                        ul
                    );
                    dlgObject.insertBefore(div, dlgObject.firstElementChild);
                }
                if (
                    dlgObject.querySelector('.e-datetimepicker') ||
                    dlgObject.querySelector('.e-datepicker')
                ) {
                    dlgObject.querySelector(
                        '.e-flm_optrdiv .e-dropdownlist'
                    ).ej2_instances[0].select = function(args) {
                        if (args.itemData.value === 'between') {
                            dlgObject.getElementsByClassName(
                                'e-flmenu-valuediv'
                            )[0].style.display = 'none';
                            dlgObject.getElementsByClassName(
                                'customdaterange'
                            )[0].style.display = 'block';
                        } else {
                            dlgObject.getElementsByClassName(
                                'e-flmenu-valuediv'
                            )[0].style.display = 'block';
                            dlgObject.getElementsByClassName(
                                'customdaterange'
                            )[0].style.display = 'none';
                        }

                        // get the min and max value of dateRangePicker
                        var currentpredicate =
                            grid.filterModule.filterModule.filterObj
                                .actualPredicate[args.columnName];
                        var min;
                        var max;
                        if (currentpredicate && currentpredicate.length === 2) {
                            for (var i = 0; i < currentpredicate.length; i++) {
                                if (
                                    currentpredicate[i].operator ===
                                    'greaterthanorequal'
                                ) {
                                    min = currentpredicate[i].value;
                                }
                                if (
                                    currentpredicate[i].operator ===
                                    'lessthanorequal'
                                ) {
                                    max = currentpredicate[i].value;
                                }
                            }
                            // bind the value to the dateRangePicker
                            daterangepicker.value = [min, max];
                            // select the Between operator
                            var flmOptr = dlgObject.querySelector(
                                '.e-flm_optrdiv .e-dropdownlist'
                            ).ej2_instances[0];
                            flmOptr.value = 'between';
                            // hide the datePicker
                            dlgObject.getElementsByClassName(
                                'e-flmenu-valuediv'
                            )[0].style.display = 'none';
                            // show the dateRangePicker
                            div1.style.display = 'block';
                        }
                    };
                    var div1 = document.createElement('div');
                    div1.classList.add('customdaterange');
                    div1.style.display = 'none';
                    var input = document.createElement('input');
                    div1.appendChild(input);
                    input.style.width = '230px';
                    let daterangepicker = new DateRangePicker({
                        //sets the place holder
                        placeholder: 'Select Range',
                        change: this.change,
                    });
                    this.pickerColumnName = args.columnName;
                    daterangepicker.appendTo(input);
                    dlgObject
                        .getElementsByClassName('e-flmenu-maindiv')[0]
                        .appendChild(div1);
                    var currentpredicate =
                        grid.filterModule.filterModule.filterObj
                            .actualPredicate[args.columnName];
                    var min;
                    var max;
                    if (currentpredicate && currentpredicate.length === 2) {
                        for (var i = 0; i < currentpredicate.length; i++) {
                            if (
                                currentpredicate[i].operator ===
                                'greaterthanorequal'
                            ) {
                                min = currentpredicate[i].value;
                            }
                            if (
                                currentpredicate[i].operator ===
                                'lessthanorequal'
                            ) {
                                max = currentpredicate[i].value;
                            }
                        }
                        daterangepicker.value = [min, max];
                        var flmOptr = dlgObject.querySelector(
                            '.e-flm_optrdiv .e-dropdownlist'
                        ).ej2_instances[0];
                        flmOptr.value = 'between';
                        dlgObject.getElementsByClassName(
                            'e-flmenu-valuediv'
                        )[0].style.display = 'none';
                        div1.style.display = 'block';
                    }
                }
            }
            if (this.grid.filterSettings.columns.length > 0) {
                this.secondLvlFilter = this.grid.filterSettings.columns.map(
                    (item) => {
                        if (this.filteredColumnId[item.field]) {
                            return {
                                ColumnName: item.field,
                                ColumnNameValues: [],
                                Columnid: this.filteredColumnId[item.field],
                                filter: localStorage.getItem('legacyProject'),
                                filterColName: 'SiteCode',
                                filterCondition: item.operator,
                                filterConditionColName: item.field,
                                filterValue: item.value,
                            };
                        } else {
                            return {
                                ColumnName: item.field,
                                ColumnNameValues: [],
                                Columnid: null,
                                filter: localStorage.getItem('legacyProject'),
                                filterColName: 'SiteCode',
                                filterCondition: item.operator,
                                filterConditionColName: item.field,
                                filterValue: item.value,
                            };
                        }
                    }
                );
            } else {
                this.secondLvlFilter = [];
            }

            if (args.action == 'filter' && args.requestType == 'filtering') {
                if (this.grid.filterModule.filterModule) {
                    this.grid.filterModule.filterModule.closeDialog();
                    this.setFilter(this.dataManager.requests.at(-1).data);
                    this.setSecondFilter(this.secondLvlFilter);
                }
            }
        },
        quickDateFilterGrouped(filter, datasetId, dateFilterColumn) {
            let tempx = this.getGridFilters({
                dataSetId: parseInt(this.datasetId),
                projectId: parseInt(this.projectIds),
            });
            let DatasetFilter = [];
            let temp = {
                field: dateFilterColumn,
                operator: 'greaterthanorequal',
                specialPredicate: filter,
                IsActive: 1,
                isGridFilter: false,
                filterType: 'Menu',
                predicate: 'and',
            };
            let addedFilter = false;
            if (tempx) {
                //if active filters contains date filter do not apply
                tempx.selectedFilters.forEach((x) => {
                    if (!x.field == dateFilterColumn) {
                        DatasetFilter.push(...tempx.selectedFilters, temp);
                        addedFilter = true;
                    }
                });
            }
            if (!addedFilter) {
                DatasetFilter.push(temp);
            }
            let tempGridFilters = {
                dataSetId: parseInt(datasetId),
                projectId: parseInt(this.projectIds),
                selectedFilters: [...DatasetFilter],
            };

            if (filter === 'Alltime') {
                let newSelectedFilters = tempGridFilters.selectedFilters;
                newSelectedFilters = newSelectedFilters.filter((clause) => {
                    return clause.field != dateFilterColumn;
                });
                tempGridFilters.selectedFilters = newSelectedFilters;
            }
            this.setGridFilters(tempGridFilters);
            if (datasetId == this.datasetId) {
                this.setDisplayFields();
                this.createDataGrid();
            }
        },
        quickDateFilterAction(filter) {
            let tempx = this.getGridFilters({
                dataSetId: parseInt(this.datasetId),
                projectId: parseInt(this.projectIds),
            });
            let DatasetFilter = [];
            let temp = {
                field: this.dateFilterColumn,
                operator: 'greaterthanorequal',
                specialPredicate: filter,
                IsActive: 1,
                isGridFilter: false,
                filterType: 'Menu',
                predicate: 'and',
            };
            let tempGridFilters = {
                dataSetId: parseInt(this.datasetId),
                projectId: parseInt(this.projectIds),
                selectedFilters: null,
            };
            let addedFilter = false;
            if (tempx) {
                //if active filters contains date filter do not apply
                tempx.selectedFilters.forEach((x) => {
                    if (!x.field == this.dateFilterColumn) {
                        DatasetFilter.push(...tempx.selectedFilters, temp);
                        addedFilter = true;
                    }
                });
            }
            if (!addedFilter) {
                DatasetFilter.push(temp);
            }
            tempGridFilters = {
                dataSetId: parseInt(this.datasetId),
                projectId: parseInt(this.projectIds),
                selectedFilters: DatasetFilter,
            };
            if (filter === 'Alltime') {
                let newSelectedFilters = tempGridFilters.selectedFilters;
                newSelectedFilters = newSelectedFilters.filter((clause) => {
                    return clause.field != this.dateFilterColumn;
                });
                tempGridFilters.selectedFilters = newSelectedFilters;
                this.$store.dispatch(
                    'emergencyManagement/clearQuickTimeFilter'
                );
            }
            this.setGridFilters(tempGridFilters);

            this.setDisplayFields();
            this.createDataGrid();
        },
        change: function(e) {
            if (e != undefined && e.value) {
                var grid = document.getElementsByClassName('e-grid')[0]
                    .ej2_instances[0];
                this.dateValue[0] = e.value[0];
                this.dateValue[1] = e.value[1]; //get date values from date range picker
                grid.filterSettings.columns = [
                    {
                        value: this.dateValue[0],
                        operator: 'greaterthanorequal',
                        field: this.pickerColumnName,
                        predicate: 'and',
                    },
                    {
                        value: this.dateValue[1],
                        operator: 'lessthanorequal',
                        field: this.pickerColumnName,
                        predicate: 'and',
                    },
                ];
            }
        },
        menuSelect: function(args) {
            if (args.item.items.length === 0) {
                var grid = this.grid;
                this.filterDialogCol = grid.filterModule.fltrDlgDetails.field;
                var col = grid.getColumnByField(this.filterDialogCol);

                let tempObj = {
                    field: this.filterDialogCol,
                    filterType: args.item.text,
                };

                this.upsert(this.filterTypeArray, tempObj);

                if (args.item.text === 'Menu') {
                    grid.filterModule.filterModule.closeDialog();

                    this.flag = true;
                    col.filter = {
                        type: 'Menu',
                    };
                    grid.refreshColumns();
                }
                if (args.item.text === 'CheckBox') {
                    this.flag = true;
                    col.filter = {
                        type: 'CheckBox',
                    };
                    grid.filterModule.filterModule.closeDialog();
                    grid.refreshColumns();
                }
            }
        },
        upsert(array, element) {
            const i = array.findIndex(
                (_element) => _element.field === element.field
            );
            if (i > -1) array[i] = element;
            else array.push(element);
        },
        getColumnNames() {
            this.columnNames = null;
            return new Promise((resolve) => {
                datasetService
                    .getDatasetColumnAliasName(this.datasetId, this.projectIds)
                    .then((res) => {
                        if (res) {
                            this.columnNames = res.data;
                        }
                        resolve('resolved');
                    })
                    .catch(() => {
                        resolve('rejected');
                    });
            });
        },

        getColumnTypes() {
            this.columnTypes = null;
            return new Promise((resolve) => {
                datasetService
                    .getDataTypeColumn(this.datasetId, this.projectIds)
                    .then((res) => {
                        this.columnTypes = res.data;
                        resolve('resolved');
                    })
                    .catch(() => {
                        resolve('rejected');
                    });
            });
        },

        setDisplayFields() {
            this.displayFields = [];
            for (const [key, value] of Object.entries(this.columnTypes)) {
                let foundAlias = this.columnNames.filter(
                    (item) => item.DatasetColumn === key
                );

                if (foundAlias[0]) {
                    if (
                        (key.includes('hyperlink') ||
                            key.includes('Hyperlink')) &&
                        !key.includes('Path') &&
                        !key.includes('Source')
                    ) {
                        this.displayFields.push({
                            field: key,
                            headerText: foundAlias[0].DatasetColumnAliasName
                                ? foundAlias[0].DatasetColumnAliasName
                                : key,
                            template: this.columnTemplate,
                            width: 200,
                        });
                    } else if (key.includes('Date') || key.includes('date')) {
                        this.displayFields.push({
                            field: key,
                            headerText: foundAlias[0].DatasetColumnAliasName
                                ? foundAlias[0].DatasetColumnAliasName
                                : key,
                            format: this.columnDateFormat,
                            type: 'datetime',
                            width: 200,
                        });
                    } else {
                        this.displayFields.push({
                            field: key,
                            headerText: foundAlias[0].DatasetColumnAliasName
                                ? foundAlias[0].DatasetColumnAliasName
                                : key,
                            type: value,
                            width: 200,
                        });
                    }
                }
            }
        },
        getColumnDateFormat() {
            return {
                type: 'dateTime',
                format: 'MM/dd/yyyy hh:mm:ss a',
            };
        },

        dataBoundGrid: function() {
            this.grid.hideScroll();
            this.grid.filterModule.customOperators.dateOperator = [
                { value: 'equal', text: 'Equal' },
                { value: 'greaterthan', text: 'Greater Than' },
                { value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
                { value: 'lessthan', text: 'Less Than' },
                { value: 'lessthanorequal', text: 'Less Than Or Equal' },
                { value: 'notequal', text: 'Not Equal' },
                { value: 'between', text: 'Between' },
                { value: 'Last12hours', text: 'Last 12 hours' },
                { value: 'Last24hours', text: 'Last 24 hours' },
                { value: 'Last48hours', text: 'Last 48 hours' },
                { value: 'Last3days', text: 'Last 3 days' },
                { value: 'Lastweek', text: 'Last week' },
                { value: 'Lastmonth', text: 'Last month' },
                { value: 'Lastquarter', text: 'Last quarter' },
                { value: 'Lastyear', text: 'Last year' },
            ];
            this.grid.filterModule.customOperators.datetimeOperator = [
                { value: 'equal', text: 'Equal' },
                { value: 'greaterthan', text: 'Greater Than' },
                { value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
                { value: 'lessthan', text: 'Less Than' },
                { value: 'lessthanorequal', text: 'Less Than Or Equal' },
                { value: 'notequal', text: 'Not Equal' },
                { value: 'between', text: 'Between' },
                { value: 'Last12hours', text: 'Last 12 hours' },
                { value: 'Last24hours', text: 'Last 24 hours' },
                { value: 'Last48hours', text: 'Last 48 hours' },
                { value: 'Last3days', text: 'Last 3 days' },
                { value: 'Lastweek', text: 'Last week' },
                { value: 'Lastmonth', text: 'Last month' },
                { value: 'Lastquarter', text: 'Last quarter' },
                { value: 'Lastyear', text: 'Last year' },
            ];

            if (this.grid) {
                if (this.flag) {
                    var grid = this.grid;
                    this.flag = false;
                    var col = grid.getColumnByField(this.filterDialogCol);
                    var colHeader = grid.getColumnHeaderByField(
                        this.filterDialogCol
                    );
                    this.filterDialogCol = '';
                    grid.filterModule.filterDialogOpen(col, colHeader);
                }
                this.$emit('storeGrid', this.grid);
                this.setFilterTypes();
            }
            this.grid.on('filter-menu-close', function(args) {
                if (
                    args.target &&
                    args.target.parentElement &&
                    args.target.parentElement.classList.contains('e-footer')
                ) {
                    // prevent the filter menu close action when click the footer buttons in dateRangePicker
                    //args.cancel = true;
                }
            });
        },

        setFreeze({ row, col }) {
            if (this.frozenRows != row || this.frozenColumns != col) {
                this.frozenRows = row;
                this.frozenColumns = col;

                const freezeToSend = {
                    dataSetId: this.datasetId,
                    projectId: parseInt(this.projectIds),
                    freezeVals: {
                        row: this.frozenRows,
                        col: this.frozenColumns,
                    },
                };
                this.setFreezeVals(freezeToSend);

                this.setDisplayFields();
                this.createDataGrid();
            }
        },
        resetDatagrid() {
            let grid = document.getElementById('PagedDataGrid');
            if (grid) {
                document.getElementById('PagedDataGrid').innerHTML = '';
            }
        },
        portalPageSettings() {
            let defaultPagination = this.portalsettings
                ? this.portalsettings.Pagination
                : 100;
            let pageSizes = [10, 100, 1000, 10000];
            pageSizes.push(defaultPagination);
            pageSizes = [...new Set(pageSizes)];
            pageSizes.sort((a, b) => a - b);
            pageSizes.push('All');
            this.pageSettings = {
                pageSize: defaultPagination ? defaultPagination : 100,
                pageSizes: pageSizes,
                pageCount: 4,
            };
        },

        async updatePaginationSize() {
            userService
                .updatePaginationCount(this.currentPageSize)
                .then((res) => {
                    this.getPortalSettings();
                    this.pageSettings.pageSize = this.currentPageSize;
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        ...mapActions('store', {
            getPortalSettings: 'getPortalSettings',
        }),
    },
    computed: {
        ...mapState({
            filteroptions: (state) => state.getAllFilterOptions.filteroptions,
            gridFilters: (state) => state.gridFilters.selectedFilters,
            currentStateUUID: (state) => state.gridFilters.currentStateUUID,
            openOtherSideTool: (state) => state.gridFilters.openOtherSideTool,
        }),

        ...mapGetters('projects', { gridDatasetId: 'getGridDatasetId' }),
        ...mapGetters('store', { portalsettings: 'portalsettings' }),
        ...mapGetters('gridFilters', { getGridFilters: 'getGridFilters' }),
        currentPageSize() {
            if (this.grid) {
                let paginationSize = this.grid.pagerModule.pageSettings
                    .properties.pageSize;
                return paginationSize;
            } else {
                return this.pageSettings.pageSize;
            }
        },
    },
    watch: {
        async datasetId(newVal, oldVal) {
            if (newVal) {
                this.frozenRows = 0;
                this.frozenColumns = 0;
                await this.getColumnNames();
                await this.getColumnTypes();
                this.setDisplayFields();
                this.createDataGrid();
            }
        },
        async currentPageSize(newVal, oldVal) {
            if (newVal && oldVal) {
                this.updatePaginationSize(newVal);
            }
        },
        async currentStateUUID(newVal, oldVal) {
            if (newVal) {
                await this.getColumnNames();
                await this.getColumnTypes();
                this.setDisplayFields();
                this.createDataGrid();
            }
        },
        async projectIds(newProjectID, oldProjectID) {
            if (newProjectID != oldProjectID) {
                await this.getColumnNames();
                await this.getColumnTypes();
                this.setDisplayFields();
                this.createDataGrid();
            }
        },
        openOtherSideTool() {
            this.$emit('navigateToDataSetTab');
        },
    },

    async activated() {
        this.$emit('navigateToDataSetTab');
    },
    async mounted() {
        await this.getColumnNames();
        await this.getColumnTypes();
        this.setDisplayFields();
        this.createDataGrid();
        this.$emit('navigateToDataSetTab');
        let state = {
            skip: 0,
            take: 10,
        };
    },
};
</script>

<style scoped>
.e-search-icon::before {
    content: '\e993';
    top: -11px !important;
    left: 10px;
}
</style>
<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);

.PagedDataGrid-container table.e-table.e-tableborder {
    width: 100% !important;
}
.PagedDataGrid-container {
    max-width: fit-content;
    margin: 0 8px;
    height: 100%;
}
.PagedDataGrid-container ::-webkit-scrollbar:hover {
    height: 10px;
}
#PagedDataGrid .e-gridpager {
    bottom: 0 !important;
}
#PagedDataGrid .e-content {
    height: calc(100vh - 285px) !important;
}
#ej2_dropdownlist_0_popup {
    max-height: 160px !important;
    box-shadow: 2px 2px 6px 2px #00000029 !important;
}
#PagedDataGrid .e-flmenu.e-control.e-dialog.e-lib.e-filter-popup {
    box-shadow: 2px 2px 6px 2px #00000029 !important;
}
#PagedDataGrid ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
}
.e-grid td.e-rowcell,
.e-grid td.e-rowcell:first-child {
    font: 1rem 'IBMPlexSans-Regular', sans-serif !important;
}
</style>
