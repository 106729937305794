var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"freeze-container"},[_c('b-modal',{ref:"modal-freeze",attrs:{"size":"lg","title":"Freeze Tool","id":"modal-freeze","ok-title":"Freeze","cancel-title":"Reset ","hide-header":"","hide-footer":""},on:{"ok":_vm.freeze,"cancel":_vm.closeModal}},[_c('div',{staticClass:"text-right cursor-pointer"},[_c('img',{staticClass:"pointer",attrs:{"src":require("../../assets/close_icon.svg")},on:{"click":_vm.closeModal}})]),_c('h5',{staticClass:"my-2 title text-center"},[_vm._v("Freeze grid")]),_c('div',{staticClass:"manage-form"},[_c('div',{staticClass:"content"},[_c('CustomFloatingLabel',{attrs:{"config":{
                        label: 'Number of columns',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.col),expression:"col"}],ref:"col",staticClass:"normal_input",attrs:{"autocomplete":"off","type":"number","id":"col","min":"0","max":_vm.colsCount - 1},domProps:{"value":(_vm.col)},on:{"input":function($event){if($event.target.composing){ return; }_vm.col=$event.target.value}}})]),_c('CustomFloatingLabel',{attrs:{"config":{
                        label: 'Number of rows',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.row),expression:"row"}],ref:"row",staticClass:"normal_input",attrs:{"id":"row","autocomplete":"off","type":"number","min":"0","max":"7"},domProps:{"value":(_vm.row)},on:{"input":function($event){if($event.target.composing){ return; }_vm.row=$event.target.value}}})]),_c('b-button',{staticClass:"applyButton assignedApply",attrs:{"id":"freeze-btn","disabled":_vm.freezeBtnState},on:{"click":function($event){return _vm.freeze()}}},[_vm._v(" Freeze ")]),_c('b-button',{staticClass:"cancelButton",attrs:{"id":"reset-btn"},on:{"click":_vm.handleReset}},[_vm._v(" Reset ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }