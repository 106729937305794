<!-- 
    This component is responsible for doing Freeze syncfusion action on the syncfusion Grid
    - prop - colsCount - sets the maximum number of columns to freeze
    - emit - freeze - freeze the grid
    - maximum number for freezing row is set to 7
 -->
<template>
    <div class="freeze-container">
        <b-modal
            size="lg"
            title="Freeze Tool"
            ref="modal-freeze"
            id="modal-freeze"
            ok-title="Freeze"
            cancel-title="Reset "
            hide-header
            hide-footer
            @ok="freeze"
            @cancel="closeModal"
        >
            <div class="text-right cursor-pointer">
                <img
                    class="pointer"
                    src="../../assets/close_icon.svg"
                    @click="closeModal"
                />
            </div>
            <h5 class="my-2 title text-center">Freeze grid</h5>
            <div class="manage-form">
                <div class="content">
                    <CustomFloatingLabel
                        :config="{
                            label: 'Number of columns',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <input
                            autocomplete="off"
                            ref="col"
                            type="number"
                            id="col"
                            v-model="col"
                            class="normal_input"
                            min="0"
                            :max="colsCount - 1"
                        />
                    </CustomFloatingLabel>

                    <CustomFloatingLabel
                        :config="{
                            label: 'Number of rows',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <input
                            id="row"
                            ref="row"
                            autocomplete="off"
                            type="number"
                            v-model="row"
                            class="normal_input"
                            min="0"
                            max="7"
                        />
                    </CustomFloatingLabel>
                    <b-button
                        id="freeze-btn"
                        :disabled="freezeBtnState"
                        @click="freeze()"
                        class="applyButton assignedApply"
                    >
                        Freeze
                    </b-button>
                    <b-button
                        id="reset-btn"
                        class="cancelButton"
                        @click="handleReset"
                    >
                        Reset
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CustomFloatingLabel from '../CustomFloatingLabel.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'GridFreeze',
    components: { CustomFloatingLabel },
    props: { colsCount: Number, datasetId: Number, projectId: Number },
    data() {
        return {
            col: 0,
            row: 0,
        };
    },
    methods: {
        openModal() {
            this.$refs['modal-freeze'].show();
        },
        closeModal() {
            this.$refs['modal-freeze'].hide();
        },
        freeze() {
            this.row = parseInt(document.getElementById('row').value);
            this.col = parseInt(document.getElementById('col').value);
            this.$emit('freeze', { row: this.row, col: this.col });
            this.closeModal();
        },
        handleReset() {
            this.row = 0;
            this.col = 0;
            this.$emit('freeze', { row: this.row, col: this.col });
        },
        preventNegative(e) {
            setTimeout(() => {
                if (
                    !(
                        (e.keyCode > 95 && e.keyCode < 106) ||
                        (e.keyCode > 47 && e.keyCode < 58) ||
                        e.keyCode === 8
                    )
                ) {
                    return false;
                }
                return true;
            }, 2000);
        },
        setFreezeVals() {
            let tempGridFilters = this.gridFilters.find((filter) => {
                if (
                    filter.dataSetId === this.datasetId &&
                    filter.projectId === this.projectId
                ) {
                    return filter;
                }
            });
            if (tempGridFilters && tempGridFilters.freezeVals) {
                this.col = tempGridFilters.freezeVals.col;
                this.row = tempGridFilters.freezeVals.row;
            } else {
                this.col = 0;
                this.row = 0;
            }
        },
    },
    computed: {
        freezeBtnState() {
            let btnDisabled =
                parseInt(this.row) > 0 || parseInt(this.col) > 0 ? false : true;
            return btnDisabled;
        },
        ...mapState({
            gridFilters: (state) => state.gridFilters.selectedFilters,
        }),
    },

    watch: {
        async datasetId(newVal) {
            if (newVal) {
                this.setFreezeVals();
            }
        },
    },
    created() {
        this.setFreezeVals();
    },
};
</script>

<style scoped>
.assignedApply,
.cancelButton {
    padding: 14px 23.46px;
}

.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
    cursor: pointer;
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #ffffff;
}
#frozen-tool {
    background-color: #368bc1;
}
</style>
